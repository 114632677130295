import React from 'react';
import MaterialTable from 'material-table';
import tableIcons from './utils/table-icons';
import { Button, CircularProgress, Fade, makeStyles } from '@material-ui/core';
import Papa from 'papaparse';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2rem',
  },
  box: {
    minHeight: '700px',
  },
  registerButton: {
    marginBottom: '1.25rem',
  },
  rowDetailsContainer: {
    padding: '1rem',
    width: '100%',
  },
  rowDetails: {
    [theme.breakpoints.down('lg')]: {
      padding: '1rem',
      maxWidth: '96vw',
    },
  },
}));

function loadFile(filePath) {
  var result = null;
  var xmlhttp = new XMLHttpRequest();
  xmlhttp.open('GET', filePath, false);
  xmlhttp.send();
  if (xmlhttp.status == 200) {
    result = xmlhttp.responseText;
  }
  return result;
}

const ClassTable = ({ redirectSearch = '' }) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // Use PapaParse to grab static CSV file. Then populate table on the fly. This makes it easier to update by dropping a new file without making the experience much worse.
  useEffect(() => {
    const csvData = loadFile('/compvisions-classes.csv');
    let result = Papa.parse(csvData.toString(), {
      header: true,
    });
    setTableData(result.data);
    setIsLoading(false);
  }, []);

  // Column and data definition for material-table
  const columns = [
    {
      title: 'Topic',
      field: 'Topic',
      cellStyle: { width: 300, minWidth: 300 },
    },
    { title: 'Dates', field: 'Dates' },
    { title: 'Length', field: 'Length' },
    { title: 'Cost', field: 'Cost' },
    {
      title: 'Outline',
      field: 'Outline',
      render: (rowData) => (
        <a
          href={`/docs/${rowData['Outline']}`}
          target="_blank"
          rel="noreferrer"
        >
          View Class Outline
        </a>
      ),
    },
    { title: 'Category', field: 'Category' },
  ];
  const data = [...tableData];

  return (
    <div className={classes.box}>
      {isLoading ? (
        <CircularProgress color="primary" />
      ) : (
        <Fade appear in>
          <div className={classes.root}>
            <Button
              component="a"
              color="primary"
              variant="contained"
              href="mailto:vision@compvisions.com"
              className={classes.registerButton}
            >
              Register Now
            </Button>
            <MaterialTable
              title="Class Schedule"
              columns={columns}
              data={data}
              icons={tableIcons}
              detailPanel={(rowData) => (
                <div className={classes.rowDetailsContainer}>
                  <div className={classes.rowDetails}>
                    {rowData['Hover_Desc'].trim().length
                      ? rowData['Hover_Desc']
                      : 'Call us for more details!'}
                  </div>
                </div>
              )}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
              options={{
                searchText: redirectSearch.length ? redirectSearch : '',
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 50],
              }}
            />
          </div>
        </Fade>
      )}
    </div>
  );
};

export default ClassTable;
